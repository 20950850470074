import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ComponentCacheService } from 'src/app/services/component-cache.service';
import { BlacklistService } from 'src/app/services/core-catalog/blacklist/blacklist.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { blockchainTypes } from 'src/app/shared/dto/commons/BlockchainType.types';
import { CryptoScamFakeDto } from 'src/app/shared/dto/core-catalog/CryptoScamFakeDto.types';
import { CryptoScamFakeFilterDto } from 'src/app/shared/dto/core-catalog/CryptoScamFakeFiltersDto.types';
import { Pageable } from 'src/app/shared/dto/Pageable.types';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';

@Component({
  selector: 'app-list-scam-fake',
  templateUrl: './list-scam-fake.component.html',
  styleUrls: ['./list-scam-fake.component.scss'],
  providers: [BlacklistService]
})
export class ListScamFakeComponent extends GenericListComponent implements OnInit, OnDestroy {

  @ViewChild('matTabGroup', { static: false }) matTabGroup!: MatTabGroup;

  readonly blockchainTypes = blockchainTypes;
  contentIsWhitelist: boolean = false;

  tabs: string[] = ['Crypto SCAM e FAKE del BO', 'Crypto SCAM e FAKE degli Utenti'];
  selectedIndex = 0;
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private readonly _blacklistService: BlacklistService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe,
    private readonly _dialog: MatDialog
  ) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'tokenAddress', 'symbol', 'blockchainType', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      filter: undefined,
      sort: undefined,
      page: 0,
      size: 10,
      blockchainType: undefined,
      length: 0
    };
  }

  list(): void {
    if(this.selectedIndex === 0){
      this.loadCatalogCryptos();
    } else {
      this.loadUsersCryptos(this.contentIsWhitelist);
    }
  }

  changeContent(): void {
    this.contentIsWhitelist = !this.contentIsWhitelist;
    this.parameters.page = 0;
    this.displayedColumns = ['tokenAddress', 'count', 'symbol', 'blockchainType', (this.contentIsWhitelist ? 'remove' : 'add')];
    this.loadUsersCryptos(this.contentIsWhitelist);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.list();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  trackByFn(index: number, item: { id: number }): number {
    return item.id || index;
  }

  selectedTabChange(matTabChangeEvent: MatTabChangeEvent): void{
    this.parameters.page = 0;
    if(matTabChangeEvent.index === 0){
      this.displayedColumns = ['id', 'tokenAddress', 'symbol', 'blockchainType', 'detail'];
      this.loadCatalogCryptos();
    } else {
      this.displayedColumns = ['tokenAddress', 'count', 'symbol', 'blockchainType', (this.contentIsWhitelist ? 'remove' : 'add')];
      this.loadUsersCryptos(this.contentIsWhitelist);
    }
  }

  addCryptoToCoreBlacklist(crypto: CryptoScamFakeDto): void {
    const message = `Confermando aggiungerai la crypto (<b>${crypto.blockchainType} : ${crypto.symbol}</b>) alla blacklist comune di tutti gli utenti, procedere?`;
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { data: message });
    dialogRef.afterClosed()
    .pipe(
      filter(x => x),
      switchMap(() => this._blacklistService.create(crypto)),
      take(1),
      tap(() => this.loadUsersCryptos(this.contentIsWhitelist))
    ).subscribe();
  }

  removeCryptoFromCoreBlacklist(crypto: CryptoScamFakeDto): void {
    const message = `Confermando rimuoverai la crypto (<b>${crypto.blockchainType} : ${crypto.symbol}</b>) dalla blacklist comune a tutti gli utenti, procedere?`;
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { data: message });
    dialogRef.afterClosed()
    .pipe(
      filter(x => x),
      switchMap(() => this._blacklistService.removeFromCatalog(crypto)),
      take(1),
      tap(() => this.loadUsersCryptos(this.contentIsWhitelist))
    ).subscribe();
  }

  private loadCatalogCryptos(): void {
    const filters: CryptoScamFakeFilterDto = { blockchainType: this.parameters.blockchainType,filter: this.parameters.filter !== '' ? this.parameters.filter : undefined};
    this._blacklistService.getCatalogsCryptos({page: this.parameters.page, size: this.parameters.size, sort: this.parameters.sort} as Pageable, filters)
    .pipe(
      takeUntil(this._unsubscribeAll),
    ).subscribe((res) => {
      this.parameters.dataSource = res.content;
      this.parameters.length = res.totalElements;
      this.parameters.showList = true;
    });
  }

  private loadUsersCryptos(contentIsWhitelist: boolean): void {
    const filters: CryptoScamFakeFilterDto = { blockchainType: this.parameters.blockchainType, filter: this.parameters.filter !== '' ? this.parameters.filter : undefined};
    const page = { page: this.parameters.page, size: this.parameters.size, sort: this.parameters.sort};
    this._blacklistService.getUsersCryptos(contentIsWhitelist, page, filters)
    .pipe(
      takeUntil(this._unsubscribeAll),
    ).subscribe((res) => {
      this.parameters.dataSource = res.content;
      this.parameters.length = res.totalElements;
      this.parameters.showList = true;
    });
  }
}
